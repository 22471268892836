<template>
  <main class="devices2 padded" style="padding-bottom:0;">
    <h2>Devices</h2>

    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="buttons">
      <select v-if="$store.getters.isAdmin" class="select-css" v-model="orgId" style="width: 200px;">
        <option :value=-1>No organization</option>
        <option v-for="org in organizations" v-bind:value="org.id" :key="org.id" :selected="org.id == $store.getters.organizationId">
          {{org.name}}
        </option>
      </select>
      <input v-model.trim="searchTerm" type="text" style="width: 300px;" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <div style="display: flex">
        <button class="white right" :disabled="loading" @click="addDeviceModal = true;">Add devices</button>
        <button class="autowidth right" :disabled="loading" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" @click="syncModal = true;">
          <img v-if="syncing" style="height: 20px; vertical-align: middle; margin-right: 5px;" src="/spinner-white.svg">
          {{(syncing ? 'Sync in progress' : 'Sync all with MDM in:')}}
        </button>
        <select style="margin-left:10px;" v-model="mdmAccount" :disabled="loading || !$store.getters.isAdmin" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
          <option>Avris</option>
          <option>Insead</option>
        </select>
      </div>
    </div>  
    
    <VueGoodTable
      ref="datatable"
      :class="{showbulkactions: $refs.datatable && $refs.datatable.selectedRows.length > 0}"
      :columns="columns"
      :rows="devices"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      max-height="calc(100lvh - var(--size-header) - var(--main-offset-vertical))"
      :fixed-header="true"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }"
    >
      <template v-slot:selected-row-actions>  
        <button class="insead autowidth right" :disabled="loading || !selectedDevices.length" @click="showDeleteModal = true">Delete</button>   
        <button class="insead autowidth right" :disabled="loading || !selectedDevices.length" @click="unassignAllSessionsBulk">Unassign Sessions</button>           
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="!loading && props.column.field == 'actions'" class="rowActions">
          <button class="icon" :title="props.row.userId ? 'Sessions of device' : ''" @click="editSessions(props.row.userId)" :disabled="!props.row.userId" :style="`${!props.row.userId ? 'opacity:0;' : ''}`">
            <img src="../assets/removesessions.svg" style="width:16px;"  />
          </button>
          <button class="icon" title="Edit" @click="editRow(props.row.id)">
            <img src="../assets/edit.svg" />
          </button>
          <button class="icon" title="Delete" @click="deleteRow(props.row.id)">
            <img src="../assets/delete.svg" />
          </button>
        </span>       
        <span v-else-if="props.column.field == 'lastLogin'">
            {{ props.row.lastLogin ? formatDate(new Date(props.row.lastLogin), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.lastLogin), $store.getters.timeZone).getFullYear()}`: '-' }}
          </span>
          <span v-else-if="props.column.field == 'created'">
            {{ formatDate(new Date(props.row.created), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.created), $store.getters.timeZone).getFullYear()}` }}
          </span>   
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </VueGoodTable>

    <span class="spinner relative" v-if="loading" />
    
    <ValidationObserver v-slot="{ invalid }" tag="div">
    <Modal2 v-if="addDeviceModal" @close="addDeviceModal = false; error = ''" class="devicemodal">      
      <h3 slot="header">Add device</h3>
      <div slot="body" style="width: 336px;">

        <label v-if="$store.getters.isAdmin">Organization</label>
        <select v-if="$store.getters.isAdmin" class="select-css" v-model="newDevice.organizationId">
          <option :value=null selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>

        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="false" /> <span style="vertical-align:middle;">Single device</span>
        </div>
        <ValidationProvider name="deviceSerial" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Serial number (Id)</label>
          <input v-model.trim="newDevice.id" :disabled="loading || bulkInvite" :class="classes" type="text"> 
        </ValidationProvider>
        <ValidationProvider name="deviceName" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Name</label>
          <input v-model.trim="newDevice.name" :disabled="loading || bulkInvite" :class="classes" type="text"> 
        </ValidationProvider>

        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="true" /> <span style="vertical-align:middle;">Multiple devices</span>
        </div>
        <textarea style="min-height: 100px;" :disabled="!bulkInvite" v-model="newDevicesCsv" placeholder="Paste CSV content here, with 'Id' and 'Name' column order."></textarea>
        <div v-if="newDevicesCsv">          
          Found <b>{{newDevicesList ? newDevicesList.length : 0}}</b> valid devices:
          <br>
          <span class="newuseremail" v-for="device in newDevicesList" :key="device.id">
            {{device.id}} - {{device.name}}
          </span>
        </div>
        
        
      </div>
      <div slot="footer" style="width: 336px;">
        <div style="margin-bottom:30px;">
          <input type="checkbox" style="vertical-align:middle; margin: 0;" v-model="syncAfterCreate" /> <span style="vertical-align:middle;">Devices are already enrolled in <b v-if="$store.getters.isAdmin">{{mdmAccount}}</b> MDM</span>
          <br>
          <span v-if="syncAfterCreate" style="font-size: 14px; color:#d5d5d6;">Devices are automatically synced with MDM after creation. Only uncheck this if you are sure the device{{(bulkInvite ? 's are' : ' is')}} not yet enrolled in MDM.</span>
          <span v-else style="font-size: 14px; color:orange;">Devices added will not be synced automatically after creation with MDM! Only use this option if you are sure the device{{(bulkInvite ? 's are' : ' is')}} not yet enrolled. <b>Are you sure?</b></span>
        </div>
        <button class="right" @click="addDevice" :disabled="bulkInvite ? (!newDevicesList || newDevicesList.length == 0) : invalid">Add</button>    
        <button class="white right" @click="addDeviceModal = false;">Cancel</button>    
      </div>
    </Modal2>    
    </ValidationObserver>


    <ValidationObserver v-slot="{ invalid }" tag="div">
    <Modal2 v-if="editDeviceModal" @close="editDeviceModal = false; error = ''" class="devicemodal">      
      <h3 slot="header">Edit device</h3>
      <div slot="body" style="width: 336px;">

        <label v-if="$store.getters.isAdmin">Organization</label>
        <select v-if="$store.getters.isAdmin" class="select-css" v-model="tempDevice.organizationId">
          <option :value=null selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>

        <ValidationProvider name="deviceSerial" rules="required" tag="div" v-slot="{ /*classes/*, errors*/ }">
          <label required>Serial number (Id)</label>
          <div style="clear:both;">{{tempDevice.id}}</div>
          <!-- <input v-model.trim="tempDevice.id" :disabled="loading" :class="classes" type="text"> 
          <div v-if="tempDevice.id != tempDeviceIdBeforeEdit" style="font-size: 14px; color:#777; margin-top:5px;">If you update device serial number, the user will need to login again.</div> -->
        </ValidationProvider>
        <ValidationProvider name="deviceName" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Name</label>
          <input v-model.trim="tempDevice.name" :disabled="loading" :class="classes" type="text"> 
        </ValidationProvider>
         
      </div>
      <div slot="footer">
        <button class="right" @click="updateDevice" :disabled="invalid || updateBtnDisabled">Save</button>    
        <button class="white right" @click="editDeviceModal = false;">Cancel</button>    
      </div>
    </Modal2>    
    </ValidationObserver>

    <Modal2 v-if="showDeleteModal" @close="showDeleteModal = loading; error = '';" class="deletedevicemodal center">      
      <h3 slot="header">Delete devices</h3>
      <div slot="body">   
        {{selectedDevices.length}} selected devices will be removed. 
        Any data (results, recordings) will be kept for potential future use, but the info from which device the data originated from will be lost. 
        These data sets will not be available from the dashboard UI.
        <h5 v-if="deviceDeleteCount && loading">Deleting device {{deviceDeleteCount}}/{{selectedDevices.length}}</h5>
        <h5 v-if="deviceDeleteCount == selectedDevices.length && !loading">Devices deleted successfully</h5>             
      </div>
      <div slot="footer">        
        <button v-if="!this.deviceDeleteCount" class="right red" @click="removeDevices" :disabled="loading">Delete devices</button>     
        <button v-if="!this.deviceDeleteCount" class="right white" @click="showDeleteModal = false; error = '';" :disabled="loading">Cancel</button> 
        <span class="spinner relative" v-if="loading" /> 
      </div>
    </Modal2>  

    <Modal2 v-if="showUserSessionsModal" @close="showUserSessionsModal = false; error = ''" class="usersessionsmodal center">      
      <h3 slot="header">
        <img src="../assets/removesessions.svg" style="width: 18px; margin-right:10px; vertical-align:middle;" />
        Assigned sessions of {{tempDevice.name}}</h3>
      <div slot="body">
        <table v-if="userSessions.length">
        <tr v-for="session in userSessions" :key="session.id"> 
          <td>{{session.id}} - </td> 
          <td>{{session.name}}</td>
        </tr>
        </table>
        <span v-else>Device has no sessions assigned</span>
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">        
        <button class="right" @click="unassignAllSessions" :disabled="loading || !userSessions.length">Remove from all sessions</button>        
        <button class="white right" @click="showUserSessionsModal = false;">Cancel</button>
      </div>
    </Modal2>  

    <Modal2 v-if="syncModal" @close="syncModal = false;" class="syncmodal center">      
      <h3 slot="header">
        <img src="../assets/cloud-sync.svg" style="width: 30px; margin-right:10px; vertical-align:middle;" />
        Synchronize with MDM</h3>
      <div slot="body">
        Synchronizing with the MDM means the system will look for the device by it's <b>serial number</b> in the MDM service, and get / update the MDM Id of the device here in the XR Platform.
        <br>
        <br>
        This is only necessary if some devices don't have this MDM Id yet, or if the device was re-enrolled in the MDM service (and thus the MDM identifier has changed).
        <br>
        <br>
        This operation can take some time to complete. If you close the browser before it has finished, the operation will still continue in the cloud, and complete eventually.
      </div>
      <div slot="footer">        
        <button class="right" @click="syncWithMdm(); syncModal = false;" :disabled="loading">Start syncing all devices</button>        
        <button class="white right" @click="syncModal = false;">Cancel</button>
      </div>
    </Modal2> 
 
    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';
import Papa from 'papaparse';
import { VueGoodTable } from 'vue-good-table';
import Modal2 from '../components/Modal2.vue'
import Snackbar from '@/components/Snackbar.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import { formatDate, toTimeZone } from '@/utils.js'

export default {
  name: 'Devices2',
  components: {
    VueGoodTable, 
    Modal2,
    Snackbar,
    ValidationProvider, 
    ValidationObserver
  },
  watch:{
    orgId: async function(){
      await this.getDevices()
    }   
  },
  data: function(){
    return {
      columns: [
        {
          label: 'Organization',
          field: 'organizationName'          
        },
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Last login',
          field: 'lastLogin',
        },
        {
          label: 'Created',
          field: 'created',
          //type: 'date',
          //dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSX', // expects 2018-03-16 this feature is broken
        }, 
        {
          label: 'User Id',
          field: 'userId',
          sortable: false
        },        
        // {
        //   label: 'Disabled',
        //   field: 'disabled',
        // },
        {
          label: 'MDM Account',
          field: 'mdmAccount',
          sortable: false
        },
        {
          label: 'MDM Id',
          field: 'mdmId',
          sortable: false
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        },
      ].filter(c => this.$store.getters.isAdmin ? true : c.label != 'Organization'),      
      newDevicesCsv: '',
      devices: [],
      organizations: [],
      userSessions: [],
      loading: false,
      syncing: false,
      mdmAccount: 'Avris',
      error: undefined,
      searchTerm: undefined,
      addDeviceModal: false,   
      editDeviceModal: false, 
      showDeleteModal: false,   
      showUserSessionsModal: false,   
      deviceDeleteCount: undefined,
      newDevice: {
        id: undefined,
        name: undefined,
        organizationId: null
      },
      bulkInvite: false,       
      tempDeviceIdBeforeEdit: undefined,
      tempDeviceNameBeforeEdit: undefined,
      tempDeviceOrganizationIdBeforeEdit: null,
      tempDevice: {
        id: undefined,
        name: undefined,
        organizationId: null
      }, 
      syncModal: false,
      syncAfterCreate: true,
      orgId: this.$store.getters.organizationId ?? -1
    }
  },
  computed: {      
    updateBtnDisabled(){
      return this.loading || !this.tempDevice.id || !this.tempDevice.name || (this.tempDevice.id == this.tempDeviceIdBeforeEdit && this.tempDevice.name == this.tempDeviceNameBeforeEdit && this.tempDevice.organizationId == this.tempDeviceOrganizationIdBeforeEdit)
    },
    selectedDevices(){
      return this.$refs.datatable.selectedRows;
    },
    newDevicesList(){
      return (this.bulkInvite ? Papa.parse(this.newDevicesCsv, {header: false, skipEmptyLines: true}).data.map(d => ({id: d[0]?.trim(), name: d[1]?.trim(), organizationId: this.newDevice.organizationId })) : [this.newDevice])
        
        .filter(d => d.id && d.name)
    },
  },
  methods: {  
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    async editSessions(userId){            
      let resp = await axios.get(`users/${userId}/sessions`)
      this.userSessions = resp.data
      this.tempDevice = this.devices.find(u => u.userId == userId)
      this.showUserSessionsModal = true
    },  
    async unassignAllSessions(){
      await axios.delete(`users/${this.tempDevice.userId}/sessions`)
      this.userSessions = []
      this.showUserSessionsModal = false
      this.$refs.snackbar.show('Device successfully removed from all sessions')
    },
    async unassignAllSessionsBulk(){
      if(!confirm('Are you sure you want to bulk unassign all sessions of selected devices?'))
        return
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        for (let index = 0; index < this.selectedDevices.length; index++) {
          if(this.selectedDevices[index].userId)
            await axios.delete(`users/${this.selectedDevices[index].userId}/sessions`)            
        }    
        this.$refs.snackbar.show('All selected devices successfully removed from all sessions')
      }
      catch(err){
        console.log("Error when bulk unassigning sessions: " + err)
        this.$refs.snackbar.show('Error when bulk unassigning sessions: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.userSessions = [] 
      }
    },
    async syncWithMdm(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.syncing = true
        let resp = await axios.post(`devices/mdmsync?account=${this.mdmAccount}`)    
        this.devices = resp.data
      }
      catch(err){
        console.log("Error when syncing devices with MDM: " + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.syncing = false
        this.$nprogress.done()
      }
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data
    },
    async removeDevices(){
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true   
        this.deviceDeleteCount = 0
        for (let index = 0; index < this.selectedDevices.length; index++) {
          const device = this.selectedDevices[index]      
          this.deviceDeleteCount++
          //console.log('deviceDeleteCount ' + this.deviceDeleteCount)
          await axios({ url: `devices/${device.id}`, method: "DELETE" })    
        }

        this.getDevices()        
      }
      catch(err){
        console.log("Error when removing devices: " + err);
        this.error = err;
        this.$refs.snackbar.show('A problem occurred when deleting devices')
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
        this.showDeleteModal = false
        this.deviceDeleteCount = undefined
      }
    },
    async editRow(id){      
      this.tempDevice = JSON.parse(JSON.stringify(this.devices.find(s => s.id == id))) 
      this.tempDeviceIdBeforeEdit = id
      this.tempDeviceNameBeforeEdit = this.tempDevice.name
      this.tempDeviceOrganizationIdBeforeEdit = this.tempDevice.organizationId
      this.editDeviceModal = true
    }, 
    async deleteRow(id){  
      let index = this.devices.findIndex(s => s.id == id)      
      this.$set(this.devices[index], 'vgtSelected', false)
      // the above is a workaround for a bug in vgTable checkbox handler, where only the second $set call is registered after manually unselecting with the checkbox
      this.devices.forEach(device => {        
        this.$set(device, 'vgtSelected', device.id == id ? true : false)
      });
      this.showDeleteModal = true
    }, 
    async getDevices(){
      try{
        this.$nprogress.start()
        this.loading = true
        let resp = await axios.get(`devices?orgId=${this.orgId}`) 
        this.devices = resp.data
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },  
    async addDevice(){
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        // eslint-disable-next-line
        let resp = await axios({ url: "devices", data: this.newDevicesList, method: "POST" })
        resp.data.forEach(newDevice => {
          newDevice.organization = this.organizations.find(o => o.id == newDevice.organizationId)
          this.devices.unshift(newDevice)
        });        
        this.newDevice = {
          id: undefined,
          name: undefined
        }
        this.newDevicesCsv = ''
        if(this.syncAfterCreate)
          this.syncWithMdm()
        this.$refs.snackbar.show(`Devices added successfully! ${(this.syncAfterCreate ? 'Syncing with MDM started.' : '')}`)
      }
      catch(err){
        console.log("Error when adding device: " + err.response)
        this.error = err
        this.$refs.snackbar.show('A problem occurred when updating device')
      }
      finally{
        if(!this.syncAfterCreate)
          this.loading = false
        this.$nprogress.done()
        this.addDeviceModal = false
      }
    }, 
    async updateDevice(){      
      try{        
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        // eslint-disable-next-line
        let resp = await axios({ url: `devices/${this.tempDeviceIdBeforeEdit}`, data: this.tempDevice, method: "POST" })
           
        let tmp = this.devices.find(d => d.id == this.tempDeviceIdBeforeEdit)      
        tmp.id = resp.data.id
        tmp.name = resp.data.name    
        tmp.userId = resp.data.userId
        tmp.created = resp.data.created
        tmp.lastLogin = resp.data.lastLogin
        tmp.organizationId = resp.data.organizationId
        tmp.organizationName = this.organizations.find(o => o.id == resp.data.organizationId).name    
        
        this.$refs.snackbar.show('Devices update successfully!')
      }
      catch(err){
        console.log("Error when updating device: " + err.response)
        this.error = err
        this.$refs.snackbar.show('A problem occurred when updating device')
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.editDeviceModal = false
      }
    }, 
  }, 
  async mounted() {
    try{
      await this.getDevices()
      await this.getOrganizations()
      this.mdmAccount = (this.$store.getters.organizationId == process.env.VUE_APP_INSEAD_ORG_ID) ? 'Insead' : 'Avris'
    }
    finally{
      this.$store.state.showLoader = false
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
main.devices2{  
  display: block;
  position: relative;
  --main-offset-vertical: 120px;

  .showbulkactions{
    --main-offset-vertical: 160px;
  }

  .newdevice{
    display: flex;

    input[type=text], select{
      // max-width: 25%;
      // float: left;
      flex-grow: 1;
      margin-left: 10px;
    }  

    button.insead{
      flex: none;
    }  
  }

  .deletedevicemodal{
    .modal-container{
      width: 600px;
    }
    .modal-footer{
      padding-top: 35px;

      > div{
        display: flex;
        justify-content: space-between;
      }

      button.insead{
        width: 270px;    
      }
    }
  }

  span.newuseremail{
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: $green2;
    color: white;
  }

  h2{
    margin: 0 0 10px 0;   
    font-weight: bold;
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
      background-size: 12px;
    }
  }

  .scrollbox{
    height: calc(100lvh - var(--size-header) - 150px - 70px);
    overflow-y: auto;    
    padding-right: 10px;
    margin-right: -13px;

    .vgt-wrap{
      border-radius: 8px;
      border: solid 1px #d7d7d8;
    }
  }

  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    td{
      padding: 5px 8px;
      border: 1px solid #dcdfe6;
      vertical-align: middle;    
    }
  }

  .rowActions{
    display: flex;
    justify-content: space-around;

    button{
      width:16px;
      height: 16px;
      min-width: unset;

      img{
        width:16px;
        height: 16px;
      }
    }
  }

  .formerror{
    position: absolute;
    top: 0;
    left: 88px;
    right: 88px;
  }

  @media (min-width: 1920px){
    --main-offset-vertical: 135px;

    .showbulkactions{
      --main-offset-vertical: 175px;
    }

    .buttons input{
      background-size: 16px;
    }

    .rowActions {
      button, button img{
        width: 20px;
        height: 20px;      
      }
    }
  }

}
</style>
